<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h3 class="mb-0">
        Información del contrato
      </h3>
    </b-card-header>
    <hr>
    <b-card-body>
      <b-alert
        variant="danger"
        :show="userData.contrato === null"
      >
        <h4 class="alert-heading">
          Este usuario no posee contrato
        </h4>
      </b-alert>
      <table
        v-if="userData.contrato != null"
        class="mt-3 mt-xl-0 w-100 my-1 mt-3"
      >
        <tr>
          <th class="pb-50">
            <feather-icon
              icon="CalendarIcon"
              class="mr-75"
            />
            <span class="font-weight-bolder">Fecha de contrato</span>
          </th>
          <td class="pb-50">
            {{ formatDate(userData.contrato.fecha_contrato) }}
          </td>
        </tr>
        <tr>
          <th class="pb-50">
            <feather-icon
              icon="FileTextIcon"
              class="mr-75"
            />
            <span class="font-weight-bolder">N Contrato</span>
          </th>
          <td class="pb-50">
            {{ userData.contrato.n_contrato }}
          </td>
        </tr>
        <tr>
          <th class="pb-50">
            <feather-icon
              icon="CheckSquareIcon"
              class="mr-75"
            />
            <span class="font-weight-bolder">Cuotas</span>
          </th>
          <td class="pb-50">
            {{ userData.contrato.cuotas_pago }}
          </td>
        </tr>
        <tr>
          <th class="pb-50">
            <feather-icon
              icon="DollarSignIcon"
              class="mr-75"
            />
            <span class="font-weight-bolder">Valor de cuotas</span>
          </th>
          <td class="pb-50">
            {{ totalPrice }} Pesos
          </td>
        </tr>
        <tr>
          <th class="pb-50">
            <feather-icon
              icon="DollarSignIcon"
              class="mr-75"
            />
            <span class="font-weight-bolder">Total a pagar</span>
          </th>
          <td class="pb-50">
            {{ totalCoutas }} Pesos
          </td>
        </tr>
      </table>
      <b-button
        v-if="!userData.contrato === null"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :disabled="!$can('update', 'contrato')"
        variant="primary"
        block
        @click="EditContract"
      >
        Editar
      </b-button>
    </b-card-body>
    <b-modal
      v-model="modalShow"
      cancel-variant="outline-secondary"
      no-close-on-esc
      no-close-on-backdrop
      centered
      size="xl"
    >
      <vue-contract
        :contrac-data="user"
        @input-n-contrato="setTipoCargo"
        @input-fecha-contrato="setTipoCargo"
        @input-total-price="setTipoCargo"
        @input-cuotas-pago="setTipoCargo"
        @input-observaciones="setTipoCargo"
        @input-contrato-pais-id="setTipoCargo"
        @input-contrato-ciudad-id="setTipoCargo"
        @input-contrato-municipio-id="setTipoCargo"
        @input-tipo-contrato-id="setTipoCargo"
        @input-tipo-regalo-id="setTipoCargo"
        @input-user-id-vendedor="setTipoCargo"
        @input-fecha-pago="setTipoCargo"
      />
      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="float-right ml-1"
            :disabled="!$can('update', 'fechapago')"
            @click="updateContract()"
          >
            Actualizar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="float-right"
            @click="closeContract()"
          >
            Cerrar
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BAlert,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import ContractData from '@/views/users/contract/ContractEdit.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BAlert,
    'vue-contract': ContractData,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formatDate,
      modalShow: false,
      // Contrato
      n_contrato: null,
      fecha_contrato: null,
      total_price: null,
      cuotas_pago: null,
      observaciones: null,
      contrato_municipio_id: null,
      contrato_ciudad_id: null,
      contrato_pais_id: null,
      tipo_contrato_id: null,
      tipo_regalo_id: [],
      user_id_vendedor: [],
      fecha_pago: [],
    }
  },
  computed: {
    totalPrice() {
      return new Intl.NumberFormat('de-DE', { currency: 'COP' }).format(this.userData.contrato.total_price)
    },
    totalCoutas() {
      let coutasPay =  this.userData.contrato.fechas_pago.reduce((acc, el) => {//eslint-disable-line
        return acc + parseFloat(el.price)
      }, 0)
      return new Intl.NumberFormat('de-DE', { currency: 'COP' }).format(coutasPay)
    },
    user: {
      get() {
        return this.userData
      },
      set(val) {
        return val
      },
    },
  },
  methods: {
    closeContract() {
      this.modalShow = false
      this.user = {}
    },
    setTipoCargo(value) {
      this.[value.key] = value.info
    },
    EditContract() {
      this.modalShow = true
    },
    updateContract() {
      const dataFechasPago = {
        fecha_pago: this.fecha_pago,
      }
      this.$http.put(`/v1/fechas-pago/${this.user.contrato.id}`, dataFechasPago).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha actualizado satisfactoriamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha actualizado.',
          },
        },
        {
          position: 'top-center',
        })
        this.$emit('edit-result')
        this.closeContract()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.data[0].message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
  },
}
</script>
