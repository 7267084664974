const stepsUsers = [
  {
    target: '#title-card-user',
    header: {
      title: 'Titulo',
    },
    content: 'Información relacionada a lo que es este modulo.',
    params: {
      enableScrolling: false,
    },
  },
  {
    target: '#avatar',
    header: {
      title: 'Foto de perfil',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Puede precionar el click encima de este cuadro para cambiar la foto.',
  },
  {
    target: '#confirmar-email',
    header: {
      title: 'Boton de confirmar email',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Se usa para el envio de un correo electronico que confirmara el dicho correo que tienes en la plataforma.',
  },
  {
    target: '#tipo-users',
    header: {
      title: 'Tipo de usuario',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Define el tipo de usuario que tiene en la plataforma este puede ser: Administrador, Estudiante, Invitado, Asesor academico y traductor.',
  },
  {
    target: '#rol-users',
    header: {
      title: 'Rol de usuario',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Define los permisos que tienes para realizar acciones dentro de la plataforma.',
  },
  {
    target: '#info-users',
    header: {
      title: 'Información del usuario',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Muestra información personal del usuario.',
  },
  {
    target: '#edit-profile-users',
    header: {
      title: 'Boton',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Al presionarlo te permite ver o editar la información de tu perfil.',
  },
  {
    target: '#edit-travel-users',
    header: {
      title: 'Boton',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Al presionarlo te permite ver o editar la información de viaje.',
  },
]

export default stepsUsers
