<template>
  <b-card>
    <b-card-header class="mb-0 pb-0 pt-0">
      <b-card-title id="title-card-user">
        Información del usuario
      </b-card-title>
      <feather-icon
        v-b-tooltip.show.top="'Ayuda'"
        icon="HelpCircleIcon"
        size="30"
        class="cursor-pointer"
        @click="tourStart"
      />
    </b-card-header>
    <hr>
    <app-tour
      name="tour-user-info"
      :steps="steps"
    />
    <b-row>
      <b-col
        cols="12"
        xl="6"
        class="justify-content-between flex-column"
      >
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <div
              class="text-center mb-2"
              @click="ActivateFormFile"
            >
              <b-avatar
                id="avatar"
                :src="getFilePath"
                :text="avatarText(userData.fullName)"
                :variant="`light-${resolveUserRoleVariant(userData.role)}`"
                size="104px"
                rounded
              />
              <p>
                Presiona para cambiar la foto
              </p>
            </div>
            <b-form-file
              id="fileInput"
              ref="file-input"
              v-model="avatar"
              class="mb-2"
              style="visibility: hidden;position: absolute;top: 0;left: -5000px;"
              placeholder="Selecciona un archivo"
              accept="image/jpeg, image/png, image/jpeg"
            />
          </b-col>
          <b-col
            class="mb-2"
            cols="12"
            md="9"
          >
            <div class="d-flex flex-column">
              <div class="mb-1">
                <h4
                  class="mb-0"
                >
                  {{ userData.fullName }}
                </h4>
                <span class="card-text">{{ userData.email }}</span>
              </div>
              <!-- [ ] Todo: Agregar funcionalidad para confirmar email -->
              <div
                class="d-flex flex-wrap"
              >
                <b-button
                  v-if="userData.confirm_email == 0"
                  id="confirmar-email"
                  :to="{ name: '', params: { id: userData.id } }"
                  variant="primary"
                >
                  Confirmar email
                </b-button>
                <b-button
                  v-else
                  variant="success"
                >
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Email Verificado</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>

        <!-- User Stats -->
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center">
              <b-avatar
                variant="light-primary"
                rounded
              >
                <feather-icon
                  icon="StarIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h6
                  id="tipo-users"
                  class="mb-0"
                >
                  Tipo de usuario
                </h6>
                <small>
                  {{ userData.user_tipo_cargo.name }}
                </small>
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center">
              <b-avatar
                variant="light-success"
                rounded
              >
                <feather-icon
                  icon="StarIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h6
                  id="rol-users"
                  class="mb-0"
                >
                  Rol
                </h6>
                <small>{{ userData.role }}</small>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        xl="6"
      >
        <table
          id="info-users"
          class="mt-3 mt-xl-0 w-100 table-user-data"
        >
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bolder">Usuario</span>
            </th>
            <td class="pb-50">
              {{ userData.username }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bolder">Genero</span>
            </th>
            <td
              v-if="userData.viaje"
              class="pb-50"
            >
              {{ userData.viaje.gender.name }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bolder">Estado civil</span>
            </th>
            <td
              v-if="userData.viaje && userData.viaje.tipoStadoCivil"
              class="pb-50"
            >
              {{ userData.viaje.tipoStadoCivil.name }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bolder">Telefonos</span>
            </th>
            <td
              v-if="userData.viaje"
            >
              {{ userData.viaje.celular }} - {{ userData.viaje.telefono }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bolder">País</span>
            </th>
            <td
              v-if="userData.viaje"
              class="pb-50"
            >
              {{ userData.viaje.municipio.name }} - {{ userData.viaje.ciudad.name }} - {{ userData.viaje.pais.name }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CompassIcon"
                class="mr-75"
              />
              <span class="font-weight-bolder">Direccion</span>
            </th>
            <td
              v-if="userData.viaje"
              class="pb-50"
            >
              {{ userData.viaje.direccion }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
        </table>
        <b-button-toolbar justify>
          <b-button-group
            class="mr-2 mb-1"
          >
            <b-button
              v-if="$can('read', 'profile')"
              id="edit-profile-users"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="'Ver datos del perfil'"
              variant="outline-primary"
              @click="EditUser"
            >
              <feather-icon icon="EditIcon" />
              Información del perfil
            </b-button>
            <b-button
              v-if="$can('read', 'viaje') && user && user.viaje != null"
              id="edit-travel-users"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="'Ver datos de viaje'"
              variant="outline-primary"
              @click="EditTraveler"
            >
              <feather-icon icon="NavigationIcon" />
              Información del viaje
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </b-col>
    </b-row>
    <b-modal
      v-model="modalShow"
      cancel-variant="outline-secondary"
      ok-title="Login"
      no-close-on-esc
      no-close-on-backdrop
      centered
      size="lg"
    >
      <template #modal-header>
        <h5>Información del perfil</h5>
      </template>
      <validation-observer
        ref="UsersForm"
      >
        <profile-data
          :profile-data="user"
          @input-email="setTipoCargo"
          @input-tipo-cargo-id="setTipoCargo"
          @input-roles="setTipoCargo"
          @input-first-name="setTipoCargo"
          @input-last-name="setTipoCargo"
          @input-status-id="setTipoCargo"
        />
      </validation-observer>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-if="$can('update', 'profile')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="float-right ml-1"
            @click="save()"
          >
            Actualizar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="float-right"
            @click="close()"
          >
            Cerrar
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="modalShowTraveler"
      cancel-variant="outline-secondary"
      ok-title="Login"
      no-close-on-esc
      no-close-on-backdrop
      :scrollable="true"
      size="xl"
    >
      <template #modal-header>
        <h5>Información del viaje</h5>
      </template>
      <validation-observer
        ref="TravelerForm"
      >
        <traveler-data
          :traveler-data="user"
          @input-tipo-documento-id="setTipoCargo"
          @input-tarjeta-identidad="setTipoCargo"
          @input-expedicion="setTipoCargo"
          @input-pasaporte="setTipoCargo"
          @input-pasp-expedicion="setTipoCargo"
          @input-pasp-vencimiento="setTipoCargo"
          @input-direccion="setTipoCargo"
          @input-date-of-birth="setTipoCargo"
          @input-lugar-nacimiento="setTipoCargo"
          @input-pais-id="setTipoCargo"
          @input-ciudad-id="setTipoCargo"
          @input-municipio-id="setTipoCargo"
          @input-tipo-stado-civil-id="setTipoCargo"
          @input-fecha-viaje-id="setTipoCargo"
          @input-tipo-gender="setTipoCargo"
          @input-tipo-grado-academico-id="setTipoCargo"
          @input-institucion-academica="setTipoCargo"
          @input-fecha-terminacion="setTipoCargo"
          @input-carrera-estudio="setTipoCargo"
          @input-visa-americana="setTipoCargo"
          @input-tipo-financiacion-id="setTipoCargo"
          @input-tipo-estudio-id="setTipoCargo"
          @input-preparatoria="setTipoCargo"
          @input-celular="setTipoCargo"
          @input-telefono="setTipoCargo"
          @input-universidad-id="setTipoCargo"
          @input-universidad-segunda-id="setTipoCargo"
          @input-pais-id-universidad="setTipoCargo"
          @input-pais-id-universidad-segunda="setTipoCargo"
          @input-ciudad-universidad-id="setTipoCargo"
          @input-ciudad-universidad-segunda-id="setTipoCargo"
          @input-date-of-irlanda="setTipoCargo"
          @input-semanas="setTipoCargo"
        />
      </validation-observer>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-if="$can('update', 'viaje')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="float-right ml-1"
            @click="saveTraveler()"
          >
            Actualizar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="float-right"
            @click="close2()"
          >
            Cerrar
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BCard, BButton, BAvatar, BRow, BCol, BFormFile, BButtonToolbar, BButtonGroup, VBTooltip,
  BCardTitle, BCardHeader,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import ProfileData from '@/views/users/profile/ProfileEdit.vue'
import TravelerData from '@/views/users/traveler/TravelerEdit.vue'
import AppTour from '@core/components/app-tour/AppTour.vue'
import stepsUsers from '@tour/tourUserInfoCard.js'

export default {
  components: {
    BCard,
    BButton,
    BButtonToolbar,
    BButtonGroup,
    BRow,
    BCol,
    BAvatar,
    BFormFile,
    AppTour,
    BCardTitle,
    BCardHeader,
    ValidationObserver,
    // Module View
    ProfileData,
    TravelerData,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
  data() {
    return {
      steps: [],
      avatar: null,
      getFilePath: this.userData.avatar,
      // Users
      emailUsers: null,
      tipo_cargo_id: [],
      roles: [],
      // profile
      first_name: null,
      last_name: null,

      // viaje
      carrera_estudio: null,
      carrera_estudio_segunda_opcion: null,
      institucion_academica: null,
      fecha_terminacion: null,
      preparatoria: null,
      lugar_nacimiento: null,
      tarjeta_identidad: null,
      expedicion: null,
      pasaporte: null,
      celular: null,
      telefono: null,
      direccion: null,
      date_of_birth: null,
      pasp_expedicion: null,
      pasp_vencimiento: null,
      visa_americana: null,
      tipo_gender: null,
      tipo_documento_id: null,
      municipio_id: null,
      ciudad_id: [],
      pais_id: null,
      tipo_financiacion_id: null,
      tipo_estudio_id: null,
      pais_id_universidad: null,
      pais_id_universidad_segunda: null,
      ciudad_universidad_id: null,
      ciudad_universidad_segunda_id: null,
      universidad_id: null,
      universidad_segunda_id: null,
      fecha_viaje_id: null,
      tipo_stado_civil_id: null,
      tipo_grado_academico_id: null,
      semanas: null,
      date_of_irlanda: null,

      // Modal
      modalShow: false,
      modalShowTraveler: false,
    }
  },
  computed: {
    user: {
      get() {
        return this.userData
      },
      set(val) {
        return val
      },
    },
  },
  watch: {
    avatar(value) {
      if (value != null) {
        this.getFilePath = URL.createObjectURL(value)
      } else {
        this.getFilePath = `${process.env.VUE_APP_URL_API}/avatar/logo-default.png`
      }
      this.savePicture()
    },
  },
  mounted() {
    this.steps = stepsUsers
  },
  methods: {
    tourStart() {
      this.$tours['tour-user-info'].start()
    },
    resolveUserRoleVariant(role) {
      if (role === 'subscriber') return 'primary'
      if (role === 'author') return 'warning'
      if (role === 'maintainer') return 'success'
      if (role === 'editor') return 'info'
      if (role === 'admin') return 'danger'
      return 'primary'
    },
    close() {
      this.modalShow = false
      this.user = {}
    },
    close2() {
      this.modalShowTraveler = false
      this.user = {}
    },
    setTipoCargo(value) {
      this.[value.key] = value.info
    },
    ActivateFormFile() {
      document.getElementById('fileInput').click()
    },
    savePicture() {
      const data = new FormData()
      data.append('avatar', this.avatar)
      this.$http.post(`/v1/users/avatar`, data).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Foto',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Se ha actualizado correctamente.',
          },
        },
        {
          position: 'top-center',
        })
        this.$emit('edit-result')
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    EditUser() {
      this.modalShow = true
    },
    EditTraveler() {
      this.modalShowTraveler = true
    },
    save() {
      this.$refs.UsersForm.validate().then(success => {
        if (success) {
          const rolId = []
          rolId.push(this.roles[0].id)
          const dataUser = {
            user_id: this.user.id,
            email: this.emailUsers,
            roles: rolId,
            first_name: this.first_name,
            last_name: this.last_name,
            status_id: this.status_id.id,
            tipo_cargo_id: this.tipo_cargo_id.id,
          }
          this.updateUsers(dataUser)
        }
      })
    },
    saveTraveler() {
      this.$refs.TravelerForm.validate().then(success => {
        if (success) {
          const dataTraveler = {
            id: this.user.id,
            carrera_estudio: this.carrera_estudio,
            carrera_estudio_segunda_opcion: this.carrera_estudio_segunda_opcion,
            institucion_academica: this.institucion_academica,
            fecha_terminacion: this.fecha_terminacion,
            preparatoria: this.preparatoria.length > 0 ? this.preparatoria[0].id : this.preparatoria.id,
            lugar_nacimiento: this.lugar_nacimiento,
            tarjeta_identidad: this.tarjeta_identidad,
            expedicion: this.expedicion,
            pasaporte: this.pasaporte,
            celular: this.celular,
            telefono: this.telefono,
            direccion: this.direccion,
            date_of_birth: this.date_of_birth,
            pasp_expedicion: this.pasp_expedicion,
            pasp_vencimiento: this.pasp_vencimiento,
            visa_americana: this.visa_americana.length > 0 ? this.visa_americana[0].id : this.visa_americana.id,
            tipo_gender: this.tipo_gender ? this.tipo_gender.id : this.tipo_gender,
            tipo_documento_id: this.tipo_documento_id ? this.tipo_documento_id.id : this.tipo_documento_id,
            municipio_id: this.municipio_id ? this.municipio_id.id : this.municipio_id,
            ciudad_id: this.ciudad_id ? this.ciudad_id.id : this.ciudad_id,
            pais_id: this.pais_id ? this.pais_id.id : this.pais_id,
            tipo_financiacion_id: this.tipo_financiacion_id ? this.tipo_financiacion_id.id : this.tipo_financiacion_id,
            tipo_estudio_id: this.tipo_estudio_id ? this.tipo_estudio_id.id : this.tipo_estudio_id,
            pais_id_universidad: this.pais_id_universidad ? this.pais_id_universidad.id : this.pais_id_universidad,
            pais_id_universidad_segunda: this.pais_id_universidad_segunda ? this.pais_id_universidad_segunda.id : this.pais_id_universidad_segunda,
            ciudad_universidad_id: this.ciudad_universidad_id ? this.ciudad_universidad_id.id : this.ciudad_universidad_id,
            ciudad_universidad_segunda_id: this.ciudad_universidad_segunda_id ? this.ciudad_universidad_segunda_id.id : this.ciudad_universidad_segunda_id,
            universidad_id: this.universidad_id ? this.universidad_id.id : this.universidad_id,
            universidad_segunda_id: this.universidad_segunda_id ? this.universidad_segunda_id.id : this.universidad_segunda_id,
            fecha_viaje_id: this.fecha_viaje_id ? this.fecha_viaje_id.id : this.fecha_viaje_id,
            tipo_stado_civil_id: this.tipo_stado_civil_id ? this.tipo_stado_civil_id.id : this.tipo_stado_civil_id,
            tipo_grado_academico_id: this.tipo_grado_academico_id ? this.tipo_grado_academico_id.id : this.tipo_grado_academico_id,
            date_of_irlanda: this.date_of_irlanda,
            semanas: this.semanas,
          }
          this.updateTraveler(dataTraveler)
        }
      })
    },
    updateUsers(data) {
      this.$http.put(`/v1/users/${data.tipo_cargo_id}`, data).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha actualizado satisfactoriamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha actualizado.',
          },
        },
        {
          position: 'top-center',
        })
        this.$emit('edit-result')
        this.close()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    updateTraveler(data) {
      this.$http.put(`/v1/users/single/viaje`, data).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha actualizado satisfactoriamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha actualizado.',
          },
        },
        {
          position: 'top-center',
        })
        this.$emit('edit-result')
        this.close2()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
  },
}
</script>

<style scoped>
  .table-user-data > tr > th {
    width: 130px;
  }
</style>
<style lang="scss">
  @import '~@core/scss/vue/libs/tour.scss';
</style>
