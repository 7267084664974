<template>
  <div>
    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <user-view-contract
            :user-data="userData"
            @edit-result="setDataUser"
          />
        </b-col>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <user-view-user-info-card
            :user-data="userData"
            @edit-result="setDataUser"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          lg="12"
        >
          <user-data-viaje :user-data="userData" />
        </b-col>
      </b-row>
      <b-row v-if="false">
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-timeline-card v-if="false" />
        </b-col>
      </b-row>

      <invoice-list v-if="false" />
    </template>
  </div>
</template>

<script>
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import userStoreModule from '@/views/users/user/userStoreModule'
import UserViewUserInfoCard from '@/components/user/infouser/UserViewUserInfoCard.vue'
import UserViewContract from '@/components/user/infouser/UserViewContract.vue'
import UserViewUserTimelineCard from '@/components/user/infouser/UserViewUserTimelineCard.vue'
// import UserViewUserDocuments from '@/components/user/infouser/UserViewUserDocuments.vue'
// import UserViewFamily from '@/components/user/infouser/UserViewFamily.vue'
import UserDataViaje from '@/components/user/infouser/UserTravelerData.vue'

export default {
  components: {
    BRow,
    BCol,

    // Local Components
    UserViewUserInfoCard,
    UserViewContract,
    UserViewUserTimelineCard,
    // UserViewUserDocuments,
    UserDataViaje,
    // UserViewFamily,
  },
  directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
  },
  data() {
    return {
      userData: null,
    }
  },
  mounted() {
    this.setDataUser()
  },
  methods: {
    setDataUser() {
      const userId = JSON.parse(localStorage.getItem('userData'))
      store.dispatch('app-user/fetchUser', { id: userId.id })
        .then(response => {
          this.userData = response.data.userData
          localStorage.removeItem('userData')
          localStorage.setItem('userData', JSON.stringify(this.userData))
          store.dispatch('userApp/setProfileData', JSON.parse(localStorage.getItem('userData')))
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.userData = undefined
          }
        })
    },
  },
}
</script>

<style>

</style>
