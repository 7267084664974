<template>
  <b-form
    @submit.prevent
  >
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Tipo de documento"
          label-for="users-tipo_documento_id"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            immediate
            name="Tipo de cargo"
          >
            <v-select
              v-model="tipo_documento_id"
              name="users-tipo_documento_id"
              label="name"
              :options="rowsTipoDocumento"
              placeholder="Seleccione una opción"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Documento de identidad"
          label-for="users-tarjeta_identidad"
        >
          <validation-provider
            #default="{ errors }"
            name="documento de identidad"
            vid="documento de identidad"
            rules="required|integer"
            immediate
          >
            <b-form-input
              id="users-tarjeta_identidad"
              v-model="tarjeta_identidad"
              placeholder="Documento de identidad"
              :state="errors.length > 0 ? false:null"
              name="users-tarjeta_identidad"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Ciudad de expedición"
          label-for="users-expedicion"
        >
          <validation-provider
            #default="{ errors }"
            name="expedicion"
            vid="expedicion"
            rules="required|alphaSpaces"
            immediate
          >
            <b-form-input
              id="users-expedicion"
              v-model="expedicion"
              placeholder="expedicion"
              :state="errors.length > 0 ? false:null"
              name="users-expedicion"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Pasaporte"
          label-for="users-pasaporte"
        >
          <validation-provider
            #default="{ errors }"
            name="pasaporte"
            vid="pasaporte"
            rules=""
          >
            <b-form-input
              id="users-pasaporte"
              v-model="pasaporte"
              placeholder="Pasaporte"
              :state="errors.length > 0 ? false:null"
              name="users-pasaporte"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <validation-provider
          #default="{ errors }"
          name="Pasaporte Fecha de expedición"
          vid="Pasaporte Fecha de expedición"
          rules=""
        >
          <label for="example-input">Pasaporte Fecha de expedición</label>
          <datepicker
            v-model="pasp_expedicion"
            placeholder="Fecha de expedición"
            :use-utc="true"
            :bootstrap-styling="true"
            format="d MMMM yyyy"
            :state="errors.length > 0 ? false : null"
            :language="espanish"
            :disabled="!$can('update', 'viaje')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <validation-provider
          #default="{ errors }"
          name="Pasaporte Fecha de vencimiento"
          vid="Pasaporte Fecha de vencimiento"
          rules=""
        >
          <label for="example-input">Pasaporte Fecha de vencimiento</label>
          <datepicker
            v-model="pasp_vencimiento"
            placeholder="Fecha de vencimiento"
            :use-utc="true"
            :bootstrap-styling="true"
            format="d MMMM yyyy"
            :state="errors.length > 0 ? false : null"
            :language="espanish"
            :disabled="!$can('update', 'viaje')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Dirección"
          label-for="users-direccion"
        >
          <validation-provider
            #default="{ errors }"
            name="direccion"
            vid="direccion"
            rules="required"
            immediate
          >
            <b-form-input
              id="users-direccion"
              v-model="direccion"
              placeholder="Dirección"
              :state="errors.length > 0 ? false:null"
              name="users-direccion"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Fecha de nacimiento"
          label-for="users-date_of_birth"
        >
          <validation-provider
            #default="{ errors }"
            name="Fecha de nacimiento"
            vid="Fecha de nacimiento"
            rules="required"
            immediate
          >
            <datepicker
              v-model="date_of_birth"
              placeholder="Fecha de nacimiento"
              input-class="form-control"
              :bootstrap-styling="true"
              :use-utc="true"
              format="d MMMM yyyy"
              :language="espanish"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Lugar Nacimiento"
          label-for="users-lugar_nacimiento"
        >
          <validation-provider
            #default="{ errors }"
            name="Lugar Nacimiento"
            vid="Lugar Nacimiento"
            rules="required|alphaSpaces"
            immediate
          >
            <b-form-input
              id="users-lugar_nacimiento"
              v-model="lugar_nacimiento"
              placeholder="Lugar Nacimiento"
              :state="errors.length > 0 ? false:null"
              name="users-lugar_nacimiento"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <b-form-group
          label="País"
          label-for="users-pais_id"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="Pais"
            immediate
          >
            <v-select
              v-model="pais_id"
              name="users-pais_id"
              label="name"
              :options="rowsPais"
              placeholder="Seleccione una opción"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <b-form-group
          label="Departamento"
          label-for="users-ciudad_id"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="Departamento"
            immediate
          >
            <v-select
              v-model="ciudad_id"
              name="users-ciudad_id"
              label="name"
              :options="rowsDepartamento"
              placeholder="Seleccione una opción"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <b-form-group
          label="Ciudad"
          label-for="users-municipio_id"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="Ciudad"
            immediate
          >
            <v-select
              v-model="municipio_id"
              name="users-municipio_id"
              label="name"
              :options="rowsCiudad"
              placeholder="Seleccione una opción"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Estado civil"
          label-for="users-tipo_stado_civil_id"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="Estado civil"
            immediate
          >
            <v-select
              v-model="tipo_stado_civil_id"
              name="users-tipo_stado_civil_id"
              label="name"
              :options="rowsEstadoCivil"
              placeholder="Seleccione una opción"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Fecha de viaje"
          label-for="users-fecha_viaje_id"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="Fecha de viaje"
            immediate
          >
            <v-select
              v-model="fecha_viaje_id"
              name="users-fecha_viaje_id"
              label="name"
              :options="rowsFechaViaje"
              placeholder="Seleccione una opción"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Genero"
          label-for="users-tipo_gender"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="Genero"
            immediate
          >
            <v-select
              v-model="tipo_gender"
              name="users-tipo_gender"
              label="name"
              :options="rowsGenero"
              placeholder="Seleccione una opción"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Grado Academico"
          label-for="users-tipo_grado_academico_id"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="Grado Academico"
            immediate
          >
            <v-select
              v-model="tipo_grado_academico_id"
              name="users-tipo_grado_academico_id"
              label="name"
              :options="rowsGradoAcademico"
              placeholder="Seleccione una opción"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Institución académica"
          label-for="users-institucion_academica"
        >
          <validation-provider
            #default="{ errors }"
            name="Institución académica"
            vid="Institución académica"
            rules="required|alphaSpaces"
            immediate
          >
            <b-form-input
              id="users-institucion_academica"
              v-model="institucion_academica"
              placeholder="Institución académica"
              :state="errors.length > 0 ? false:null"
              name="users-institucion_academica"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Fecha de terminación de estudios"
          label-for="users-fecha_terminacion"
        >
          <validation-provider
            #default="{ errors }"
            name="Fecha de terminación de estudios"
            vid="Fecha de terminación de estudios"
            rules="required|integer"
            immediate
          >
            <b-form-input
              id="users-fecha_terminacion"
              v-model="fecha_terminacion"
              placeholder="Fecha de terminación de estudios"
              :state="errors.length > 0 ? false:null"
              name="users-fecha_terminacion"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Carrera o tema de estudio"
          label-for="users-carrera_estudio"
        >
          <validation-provider
            #default="{ errors }"
            name="Carrera o tema de estudio"
            vid="carrera_estudio"
            rules="required|alphaSpaces"
            immediate
          >
            <b-form-input
              id="users-carrera_estudio"
              v-model="carrera_estudio"
              placeholder="Carrera o tema de estudio"
              :state="errors.length > 0 ? false:null"
              name="users-carrera_estudio"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Segunda Carrera de estudio"
          label-for="users-carrera_estudio_segunda_opcion"
        >
          <validation-provider
            #default="{ errors }"
            name="Segunda Carrera de estudio"
            vid="Segunda Carrera de estudio"
            rules="required|alphaSpaces"
            immediate
          >
            <b-form-input
              id="users-carrera_estudio_segunda_opcion"
              v-model="carrera_estudio_segunda_opcion"
              name="users-carrera_estudio_segunda_opcion"
              placeholder="Segunda Carrera de estudio"
              :state="errors.length > 0 ? false:null"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Visa americana"
          label-for="users-visa_americana"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="Visa americana"
            immediate
          >
            <v-select
              v-model="visa_americana"
              name="users-visa_americana"
              label="name"
              :options="rowsVisa"
              placeholder="Seleccione una opción"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <b-form-group
          label="¿Quién Financia mis Estudios?"
          label-for="users-tipo_financiacion_id"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="¿Quién Financia mis Estudios?"
            immediate
          >
            <v-select
              v-model="tipo_financiacion_id"
              name="users-tipo_financiacion_id"
              label="name"
              :options="rowsFinancyStudy"
              placeholder="Seleccione una opción"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <b-form-group
          label="Tipo de Estudio"
          label-for="users-tipo_estudio_id"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="Tipo de Estudio"
            immediate
          >
            <v-select
              v-model="tipo_estudio_id"
              name="users-tipo_estudio_id"
              label="name"
              :options="rowsTipoStudio"
              placeholder="Seleccione una opción"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Preparatoria"
          label-for="users-Preparatoria"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="Preparatoria"
            immediate
          >
            <v-select
              v-model="preparatoria"
              name="users-Preparatoria"
              label="name"
              :options="rowsVisa"
              placeholder="Seleccione una opción"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Celular"
          label-for="users-celular"
        >
          <validation-provider
            #default="{ errors }"
            name="Celular"
            vid="celular"
            rules="required|integer"
            immediate
          >
            <b-form-input
              id="users-celular"
              v-model="celular"
              placeholder="celular"
              :state="errors.length > 0 ? false:null"
              name="users-celular"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Teléfono"
          label-for="users-telefono"
        >
          <validation-provider
            #default="{ errors }"
            name="telefono"
            vid="telefono"
            rules="required|integer"
            immediate
          >
            <b-form-input
              id="users-telefono"
              v-model="telefono"
              placeholder="telefono"
              :state="errors.length > 0 ? false:null"
              name="users-telefono"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <b-form-group
          label="País de viaje (*)"
          label-for="users-pais_id"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="Pais de viaje"
            immediate
          >
            <v-select
              v-model="pais_id_universidad"
              name="users-pais_id"
              label="name"
              :options="rowsPaisViaje"
              placeholder="Seleccione una opción"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <b-form-group
          label="Ciudad a donde viaje (*)"
          label-for="users-ciudad_universidad_id"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="Ciudad a donde viaje"
            immediate
          >
            <v-select
              v-model="ciudad_universidad_id"
              name="users-ciudad_universidad_id"
              label="name"
              :options="rowsCiudadRusia"
              placeholder="Seleccione una opción"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        v-if="(pais_id_universidad && pais_id_universidad.id != 15)"
        cols="12"
        sm="4"
      >
        <b-form-group
          label="Universidad (*)"
          label-for="users-universidad_id"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="Universidad"
            immediate
          >
            <v-select
              v-model="universidad_id"
              name="users-universidad_id"
              label="name"
              :options="rowsUniversidad1"
              placeholder="Seleccione una opción"
              :disabled="!$can('update', 'viaje')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        v-if="(pais_id_universidad && pais_id_universidad.id == 97) || (pais_id_universidad && pais_id_universidad.id == 60) || (pais_id_universidad && pais_id_universidad.id == 15)"
        cols="12"
        md="6"
      >
        <b-form-group
          label="Fecha de viaje"
          label-for="users-date_of_birth"
        >
          <validation-provider
            #default="{ errors }"
            name="Fecha de viaje"
            vid="Fecha de viaje"
            rules="required"
            immediate
          >
            <datepicker
              v-model="date_of_irlanda"
              placeholder="Fecha de viaje"
              input-class="form-control"
              :bootstrap-styling="true"
              :use-utc="true"
              format="d MMMM yyyy"
              :language="espanish"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        v-if="(pais_id_universidad && pais_id_universidad.id == 97) || (pais_id_universidad && pais_id_universidad.id == 60) || (pais_id_universidad && pais_id_universidad.id == 15)"
        cols="12"
        md="6"
      >
        <b-form-group
          label="Semanas de estadia"
          label-for="users-telefono"
        >
          <validation-provider
            #default="{ errors }"
            name="semanas"
            vid="semanas"
            rules="integer|required"
            immediate
          >
            <b-form-input
              id="users-semanas"
              v-model="semanas"
              placeholder="Semanas de estadia"
              :state="errors.length > 0 ? false:null"
              name="users-semanas"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import { es } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    ValidationProvider,
    Datepicker,
  },
  props: {
    travelerData: {
      type: Object,
      require: false,
      default: () => {},
    },
  },
  data() {
    return {
      // viaje
      carrera_estudio: null,
      carrera_estudio_segunda_opcion: null,
      institucion_academica: null,
      fecha_terminacion: null,
      preparatoria: null,
      lugar_nacimiento: null,
      tarjeta_identidad: null,
      expedicion: null,
      pasaporte: null,
      celular: null,
      telefono: null,
      direccion: null,
      date_of_birth: null,
      pasp_expedicion: null,
      pasp_vencimiento: null,
      visa_americana: null,
      tipo_gender: null,
      tipo_documento_id: null,
      municipio_id: null,
      ciudad_id: null,
      pais_id: null,
      tipo_financiacion_id: null,
      tipo_estudio_id: null,
      pais_id_universidad: null,
      pais_id_universidad_segunda: null,
      ciudad_universidad_id: null,
      ciudad_universidad_segunda_id: null,
      universidad_id: null,
      universidad_segunda_id: null,
      fecha_viaje_id: null,
      tipo_stado_civil_id: null,
      tipo_grado_academico_id: null,
      semanas: null,
      date_of_irlanda: null,
      itemDayPay: [],

      // select data
      rowsTipoDocumento: [],
      rowsPais: [],
      rowsDepartamento: [],
      rowsCiudad: [],
      rowsEstadoCivil: [],
      rowsFechaViaje: [],
      rowsGenero: [],
      rowsGradoAcademico: [],
      rowsVisa: [
        {
          id: 1,
          name: 'Si',
        },
        {
          id: 0,
          name: 'No',
        },
      ],
      rowsFinancyStudy: [],
      rowsTipoStudio: [],
      rowsPaisViaje: [],
      rowsPaisViaje2: [],
      rowsCiudadRusia: [],
      rowsUniversidad1: [],
      rowsUniversidad2: [],
      isLoadingDocumento: false,
      isLoadingPais: false,
      isLoadingDepartamento: false,
      isLoadingCiudad: false,
      isLoadingEstadoCivil: false,
      isLoadingFechaViaje: false,
      isLoadingGenero: false,
      isLoadingGradoAcademico: false,
      isLoadingVisa: false,
      isLoadingFinancyStudy: false,
      isLoadingTipoStudio: false,
      isLoadingCiudadRusia: false,
      isLoadingUniversidad1: false,
      isLoadingUniversidad2: false,
      espanish: es,
    }
  },
  watch: {
    tipo_documento_id(value) {
      const tipoDocumentoId = {
        key: 'tipo_documento_id',
        info: value,
      }
      this.$emit('input-tipo-documento-id', tipoDocumentoId)
    },
    tarjeta_identidad(value) {
      const tarjetaIdentidad = {
        key: 'tarjeta_identidad',
        info: value,
      }
      this.$emit('input-tarjeta-identidad', tarjetaIdentidad)
    },
    expedicion(value) {
      const expedicion = {
        key: 'expedicion',
        info: value,
      }
      this.$emit('input-expedicion', expedicion)
    },
    pasaporte(value) {
      const pasaporte = {
        key: 'pasaporte',
        info: value,
      }
      this.$emit('input-pasaporte', pasaporte)
    },
    pasp_expedicion(value) {
      const paspExpedicion = {
        key: 'pasp_expedicion',
        info: this.customFormatter(value),
      }
      this.$emit('input-pasp-expedicion', paspExpedicion)
    },
    pasp_vencimiento(value) {
      const paspVencimiento = {
        key: 'pasp_vencimiento',
        info: this.customFormatter(value),
      }
      this.$emit('input-pasp-vencimiento', paspVencimiento)
    },
    direccion(value) {
      const direccion = {
        key: 'direccion',
        info: value,
      }
      this.$emit('input-direccion', direccion)
    },
    date_of_birth(value) {
      const dateOfBirth = {
        key: 'date_of_birth',
        info: this.customFormatter(value),
      }
      this.$emit('input-date-of-birth', dateOfBirth)
    },
    lugar_nacimiento(value) {
      const lugarNacimiento = {
        key: 'lugar_nacimiento',
        info: value,
      }
      this.$emit('input-lugar-nacimiento', lugarNacimiento)
    },
    pais_id(val, oldValue) {
      const paisId = {
        key: 'pais_id',
        info: val,
      }
      this.$emit('input-pais-id', paisId)
      if (val) {
        this.getDepartamento(val.id)
        if (oldValue && val.name !== oldValue.name) {
          this.ciudad_id = null
        }
      }
    },
    ciudad_id(val, oldValue) {
      const ciudadId = {
        key: 'ciudad_id',
        info: val,
      }
      this.$emit('input-ciudad-id', ciudadId)
      if (val) {
        this.getCiudad(val.id)
        if (oldValue && val.name !== oldValue.name) {
          this.municipio_id = null
        }
      }
    },
    municipio_id(val) {
      const municipioId = {
        key: 'municipio_id',
        info: val,
      }
      this.$emit('input-municipio-id', municipioId)
    },
    tipo_stado_civil_id(val) {
      const tipoStadoCivilId = {
        key: 'tipo_stado_civil_id',
        info: val,
      }
      this.$emit('input-tipo-stado-civil-id', tipoStadoCivilId)
    },
    fecha_viaje_id(val) {
      const fechaViajeId = {
        key: 'fecha_viaje_id',
        info: val,
      }
      this.$emit('input-fecha-viaje-id', fechaViajeId)
    },
    tipo_gender(val) {
      const tipoGender = {
        key: 'tipo_gender',
        info: val,
      }
      this.$emit('input-tipo-gender', tipoGender)
    },
    tipo_grado_academico_id(val) {
      const tipoGradoAcademicoId = {
        key: 'tipo_grado_academico_id',
        info: val,
      }
      this.$emit('input-tipo-grado-academico-id', tipoGradoAcademicoId)
    },
    institucion_academica(val) {
      const institucionAcademica = {
        key: 'institucion_academica',
        info: val,
      }
      this.$emit('input-institucion-academica', institucionAcademica)
    },
    fecha_terminacion(val) {
      const fechaTerminacion = {
        key: 'fecha_terminacion',
        info: val,
      }
      this.$emit('input-fecha-terminacion', fechaTerminacion)
    },
    carrera_estudio(val) {
      const carreraEstudio = {
        key: 'carrera_estudio',
        info: val,
      }
      this.$emit('input-carrera-estudio', carreraEstudio)
    },
    carrera_estudio_segunda_opcion(val) {
      const carreraEstudioSegundaOpcion = {
        key: 'carrera_estudio_segunda_opcion',
        info: val,
      }
      this.$emit('input-carrera-estudio', carreraEstudioSegundaOpcion)
    },
    visa_americana(val) {
      const visaAmericana = {
        key: 'visa_americana',
        info: val,
      }
      this.$emit('input-visa-americana', visaAmericana)
    },
    tipo_financiacion_id(val) {
      const tipoFinanciacionId = {
        key: 'tipo_financiacion_id',
        info: val,
      }
      this.$emit('input-tipo-financiacion-id', tipoFinanciacionId)
    },
    tipo_estudio_id(val) {
      const tipoEstudioId = {
        key: 'tipo_estudio_id',
        info: val,
      }
      this.$emit('input-tipo-estudio-id', tipoEstudioId)
    },
    preparatoria(val) {
      const preparatoria = {
        key: 'preparatoria',
        info: val,
      }
      this.$emit('input-preparatoria', preparatoria)
    },
    celular(val) {
      const celular = {
        key: 'celular',
        info: val,
      }
      this.$emit('input-celular', celular)
    },
    telefono(val) {
      const telefono = {
        key: 'telefono',
        info: val,
      }
      this.$emit('input-telefono', telefono)
    },
    universidad_id(val) {
      const universidadId = {
        key: 'universidad_id',
        info: val,
      }
      this.$emit('input-universidad-id', universidadId)
    },
    universidad_segunda_id(val) {
      const universidadSegundaId = {
        key: 'universidad_segunda_id',
        info: val,
      }
      this.$emit('input-universidad-segunda-id', universidadSegundaId)
    },
    pais_id_universidad(val, oldValue) {
      const paisIdUniversidad = {
        key: 'pais_id_universidad',
        info: val,
      }
      this.$emit('input-pais-id-universidad', paisIdUniversidad)
      if (val) {
        this.getCiudadRusia(val.id)
        if (oldValue && val.name !== oldValue.name) {
          this.ciudad_universidad_id = null
        }
      }
    },
    ciudad_universidad_id(val, oldValue) {
      const ciudadUniversidadId = {
        key: 'ciudad_universidad_id',
        info: val,
      }
      this.$emit('input-ciudad-universidad-id', ciudadUniversidadId)
      if (val) {
        this.getUniversidadByCiudad(val.id)
        if (oldValue && val.name !== oldValue.name) {
          this.universidad_id = null
        }
      }
    },
    pais_id_universidad_segunda(val, oldValue) {
      const paisIdUniversidadSegunda = {
        key: 'pais_id_universidad_segunda',
        info: val,
      }
      this.$emit('input-pais-id-universidad-segunda', paisIdUniversidadSegunda)
      if (val) {
        this.getCiudadRusia2(val.id)
        if (oldValue && val.name !== oldValue.name) {
          this.ciudad_universidad_segunda_id = null
        }
      }
    },
    ciudad_universidad_segunda_id(val, oldValue) {
      const ciudadUniversidadSegundaId = {
        key: 'ciudad_universidad_segunda_id',
        info: val,
      }
      this.$emit('input-ciudad-universidad-segunda-id', ciudadUniversidadSegundaId)
      if (val) {
        this.getUniversidadByCiudad2(val.id)
        if (oldValue && val.name !== oldValue.name) {
          this.universidad_segunda_id = null
        }
      }
    },
    date_of_irlanda(value) {
      const dateOfIrlanda = {
        key: 'date_of_irlanda',
        info: this.customFormatter(value),
      }
      this.$emit('input-date-of-irlanda', dateOfIrlanda)
    },
    semanas(value) {
      const semanas = {
        key: 'semanas',
        info: value,
      }
      this.$emit('input-semanas', semanas)
    },
  },
  mounted() {
    this.getTypeCedula()
    this.getPais()
    this.getPaisViaje()
    this.getPaisViajeSegunda()
    this.getStadoCivil()
    this.getFechaViaje()
    this.getGender()
    this.getGradoAcademico()
    this.getFinancyStudy()
    this.getTipoStudio()
    this.setTravelerData()
  },
  methods: {
    customFormatter(date) {
      return moment(date).add(5, 'hours').format('YYYY-MM-DD')
    },
    setTravelerData() {
      if (Object.keys(this.travelerData).length > 0) {
        this.carrera_estudio = this.travelerData.viaje.carrera_estudio
        this.carrera_estudio_segunda_opcion = this.travelerData.viaje.carrera_estudio_segunda_opcion
        this.institucion_academica = this.travelerData.viaje.institucion_academica
        this.fecha_terminacion = this.travelerData.viaje.fecha_terminacion
        this.setPreparatoria(this.travelerData.viaje.preparatoria)
        this.setVisa(this.travelerData.viaje.visa_americana)
        this.lugar_nacimiento = this.travelerData.viaje.lugar_nacimiento
        this.tarjeta_identidad = this.travelerData.viaje.tarjeta_identidad
        this.expedicion = this.travelerData.viaje.expedicion
        this.pasaporte = this.travelerData.viaje.pasaporte
        this.celular = this.travelerData.viaje.celular
        this.telefono = this.travelerData.viaje.telefono
        this.direccion = this.travelerData.viaje.direccion
        this.date_of_birth = this.travelerData.viaje.date_of_birth
        this.pasp_expedicion = this.travelerData.viaje.pasp_expedicion
        this.pasp_vencimiento = this.travelerData.viaje.pasp_vencimiento
        this.tipo_gender = this.travelerData.viaje.gender
        this.tipo_documento_id = this.travelerData.viaje.tipoDocumento
        this.municipio_id = this.travelerData.viaje.municipio
        this.ciudad_id = this.travelerData.viaje.ciudad
        this.pais_id = this.travelerData.viaje.pais
        this.tipo_financiacion_id = this.travelerData.viaje.TipoFinanciacion
        this.tipo_estudio_id = this.travelerData.viaje.TipoEstudio
        this.pais_id_universidad = this.travelerData.viaje.paisUniversidad
        this.pais_id_universidad_segunda = this.travelerData.viaje.paisUniversidadSegunda
        this.ciudad_universidad_id = this.travelerData.viaje.ciudadUniversidad
        this.ciudad_universidad_segunda_id = this.travelerData.viaje.ciudadUniversidadSegunda
        this.universidad_id = this.travelerData.viaje.universidad
        this.universidad_segunda_id = this.travelerData.viaje.universidadSegunda
        this.fecha_viaje_id = this.travelerData.viaje.FechaViaje
        this.tipo_stado_civil_id = this.travelerData.viaje.tipoStadoCivil
        this.tipo_grado_academico_id = this.travelerData.viaje.tipoGradoAcademico
        this.date_of_irlanda = this.travelerData.viaje.date_of_irlanda
        this.semanas = this.travelerData.viaje.semanas
      }
    },
    getTypeCedula() {
      this.isLoadingDocumento = true
      this.$http.get('/v1/tipo-documento/get/')
        .then(res => {
          this.rowsTipoDocumento = res.data
          this.isLoadingDocumento = false
        })
    },
    getPais() {
      this.isLoadingPais = true
      this.$http.get('/v1/config-pais/get/status?countries=45, 28')
        .then(res => {
          this.isLoadingPais = false
          this.rowsPais = res.data
        })
    },
    getPaisViaje() {
      this.isLoadingPais = true
      this.$http.get('/v1/config-pais/get/status?countries=97, 187, 192, 149, 60, 15')
        .then(res => {
          this.isLoadingPais = false
          this.rowsPaisViaje = res.data
        })
    },
    getPaisViajeSegunda() {
      this.isLoadingPais = true
      this.$http.get('/v1/config-pais/get/status?countries=97, 187, 192, 15')
        .then(res => {
          this.isLoadingPais = false
          this.rowsPaisViaje2 = res.data
        })
    },
    getDepartamento(id) {
      this.isLoading = true
      this.$http.get(`/v1/config-ciudad/get/${id}`)
        .then(res => {
          this.isLoading = false
          this.rowsDepartamento = res.data
          if (this.ciudad_id_select) {
            this.ciudad_id = this.ciudad_id_select
          }
        })
    },
    getCiudad(id) {
      this.isLoading = true
      this.$http.get(`/v1/config-municipio/get/${id}`)
        .then(res => {
          this.isLoading = false
          this.rowsCiudad = res.data
          if (this.municipio_id_select) {
            this.municipio_id = this.municipio_id_select
          }
        })
    },
    getStadoCivil() {
      this.$http.get('/v1/stado-civil/get/')
        .then(res => {
          this.rowsEstadoCivil = res.data
        })
    },
    getFechaViaje() {
      this.$http.get('/v1/fecha-viaje/get/')
        .then(res => {
          this.rowsFechaViaje = res.data
        })
    },
    getGender() {
      this.$http.get('/v1/tipo-gender/get/')
        .then(res => {
          this.rowsGenero = res.data
        })
    },
    getGradoAcademico() {
      this.$http.get('/v1/grado-academico/get/')
        .then(res => {
          this.rowsGradoAcademico = res.data
        })
    },
    getFinancyStudy() {
      this.$http.get('/v1/financing/get/')
        .then(res => {
          this.rowsFinancyStudy = res.data
        })
    },
    getTipoStudio() {
      this.$http.get('/v1/tipo-studio/get/')
        .then(res => {
          this.rowsTipoStudio = res.data
        })
    },
    getCiudadRusia(paisId) {
      this.isLoading = true
      this.$http.get(`/v1/config-ciudad/get/${paisId}`)
        .then(res => {
          this.isLoading = false
          this.rowsCiudadRusia = res.data
        })
    },
    getCiudadRusia2(paisId) {
      this.isLoading = true
      this.$http.get(`/v1/config-ciudad/get/${paisId}`)
        .then(res => {
          this.isLoading = false
          this.rowsCiudadRusia2 = res.data
        })
    },
    getUniversidadByCiudad(id) {
      return new Promise(resolve => {
        this.isLoading = true
        this.$http.get(`/v1/config-universidad/get/${id}`)
          .then(res => {
            this.isLoading = false
            this.rowsUniversidad1 = res.data
            resolve(this.rowsUniversidad1)
          })
      })
    },
    getUniversidadByCiudad2(id) {
      this.isLoading = true
      this.$http.get(`/v1/config-universidad/get/${id}`)
        .then(res => {
          this.isLoading = false
          this.rowsUniversidad2 = res.data
        })
    },
    setPreparatoria(value) {
      this.preparatoria = this.rowsVisa.filter(row => row.id === value)
    },
    setVisa(value) {
      this.visa_americana = this.rowsVisa.filter(row => row.id === value)
    },
  },
}
</script>
