const stepsUsers = [
  {
    target: '#title-card-user-travel',
    header: {
      title: 'Titulo',
    },
    content: 'Información relacionada a lo que es este modulo.',
    params: {
      enableScrolling: false,
    },
  },
  {
    target: '#fecha-viaje',
    header: {
      title: 'Fecha de viaje',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Información relacionada a la fecha en la sera tu viaje.',
  },
  {
    target: '#finan-study',
    header: {
      title: 'Quien financia mis estudios',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Se refiere a quien o como financiaras tus pagos.',
  },
  {
    target: '#grado-academico',
    header: {
      title: 'Grado académico',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Define el título otorgado por un colegio o universidad.',
  },
  {
    target: '#carrera-estudio',
    header: {
      title: 'Carrera de estudio',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Define la carrera que piensas estudiar.',
  },
  {
    target: '#ciudad-universidad',
    header: {
      title: 'Ciudad de la universidad',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Define en que ciudad esta ubicada la universidad.',
  },
  {
    target: '#universidad',
    header: {
      title: 'Universidad',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Define el nombre de la universidad donde cursaras tus estudios.',
  },
  {
    target: '#segunda-carrera-estudio',
    header: {
      title: 'Segunda carrera de estudio',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Define la segunda carrera que piensas estudiar en caso de que la otra no este disponible.',
  },
  {
    target: '#segunda-ciudad-universidad',
    header: {
      title: 'Segunda Ciudad de universidad',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Define una alternativa de la ciudad donde esta ubicada la universidad.',
  },
  {
    target: '#segunda-universidad',
    header: {
      title: 'Segunda Universidad',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Define el nombre de la universidad donde cursaras tus estudios.',
  },
  {
    target: '#tipo-estudio',
    header: {
      title: 'Tipo de estudio',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Define el nivel de estudio que estudiaras.',
  },
  {
    target: '#institucion-academica',
    header: {
      title: 'Institución académica',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Define donde cursaste tus estudios anteriores.',
  },
  {
    target: '#fecha-termino-estudios',
    header: {
      title: 'Fecha en la que termino estudios',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Define en la que terminaste tus estudios anteriores.',
  },
  {
    target: '#preparatoria',
    header: {
      title: 'Preparatoria',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Define si estudiaras la preparatoria al llevar a tu destino.',
  },
  {
    target: '#lugar-nacimiento',
    header: {
      title: 'Lugar de nacimiento',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Define Lugar en que ha nacido.',
  },
  {
    target: '#documento-identidad',
    header: {
      title: 'Documento de identidad',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Define documento que contiene datos de identificación personal.',
  },
  {
    target: '#pasaporte',
    header: {
      title: 'Pasaporte',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Define documento que contiene datos de identificación personal.',
  },
  {
    target: '#fecha-expedicion',
    header: {
      title: 'Fecha de expedición',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Define fecha de expedición de pasaporte.',
  },
  {
    target: '#fecha-vencimiento',
    header: {
      title: 'Fecha de vencimiento',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Define fecha de expedición de pasaporte.',
  },
  {
    target: '#visa-americana',
    header: {
      title: 'Visa americana',
    },
    params: {
      enableScrolling: false,
    },
    content: 'Define si tienes visa americana.',
  },
]

export default stepsUsers
