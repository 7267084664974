<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col cols="12">
        <b-form @submit.prevent>
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group
                label="Nombre"
                label-for="users-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nombre"
                  vid="nombre"
                  rules="required|alphaSpaces"
                  immediate
                >
                  <b-form-input
                    id="users-name"
                    v-model="first_name"
                    placeholder="Nombre"
                    :state="errors.length > 0 ? false : null"
                    name="users-name"
                    :disabled="!$can('update', 'profile')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group
                label="Apellidos"
                label-for="users-apellido"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Apellidos"
                  vid="apellidos"
                  rules="required|alphaSpaces"
                  immediate
                >
                  <b-form-input
                    id="users-apellido"
                    v-model="last_name"
                    placeholder="Apellidos"
                    :state="errors.length > 0 ? false : null"
                    name="users-apellido"
                    :disabled="!$can('update', 'profile')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group
                label="Email"
                label-for="users-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                  immediate
                >
                  <b-form-input
                    id="users-email"
                    v-model="emailUsers"
                    placeholder="Email"
                    :state="errors.length > 0 ? false : null"
                    name="users-email"
                    :disabled="!$can('update', 'profile')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group
                label="Rol"
                label-for="users-roles"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Rol"
                  immediate
                >
                  <v-select
                    v-model="roles"
                    name="users-roles"
                    label="name"
                    :loading="isLoadingRoles"
                    :disabled="!isAdmin"
                    :options="rowsRoles"
                    placeholder="Seleccione una opción"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group
                label="Tipo de cargo"
                label-for="users-tipo_cargo_id"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Tipo de cargo"
                  immediate
                >
                  <v-select
                    v-model="tipo_cargo_id"
                    name="users-tipo_cargo_id"
                    label="name"
                    :disabled="!isAdmin"
                    :loading="isLoadingCargo"
                    :options="rowsTipoCargo"
                    placeholder="Seleccione una opción"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group
                label="Estado"
                label-for="users-status_id"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Estado"
                  immediate
                >
                  <v-select
                    v-model="status_id"
                    name="users-status_id"
                    label="name"
                    :disabled="!isAdmin"
                    :loading="isLoadingStatus"
                    :options="rowsStatus"
                    placeholder="Seleccione una opción"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import { ValidationProvider } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BContainer,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    BContainer,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  props: {
    profileData: {
      type: Object,
      require: false,
      default: () => {},
    },
  },
  data() {
    return {
      // Users
      emailUsers: null,
      tipo_cargo_id: [],
      roles: [],
      password: null,
      status_id: [],
      // profile
      avatar: null,
      first_name: null,
      last_name: null,
      getFilePathTraducido: `${process.env.VUE_APP_URL_API}/avatar/logo-default.png`,

      // select data
      rowsRoles: [],
      rowsStatus: [],
      rowsTipoCargo: [],
      isLoadingRoles: false,
      isLoadingStatus: false,
      isLoadingCargo: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    isAdmin() {
      return this.profileData.role === 'administrator'
    },
  },
  watch: {
    emailUsers(value) {
      const emailUsers = {
        key: 'emailUsers',
        info: value,
      }
      this.$emit('input-email', emailUsers)
    },
    tipo_cargo_id(value) {
      const tipoCargo = {
        key: 'tipo_cargo_id',
        info: value,
      }
      this.$emit('input-tipo-cargo-id', tipoCargo)
    },
    roles(value) {
      const roles = {
        key: 'roles',
        info: value,
      }
      this.$emit('input-roles', roles)
    },
    password(value) {
      const password = {
        key: 'password',
        info: value,
      }
      this.$emit('input-password', password)
    },
    avatar(value) {
      if (value != null) {
        this.getFilePathTraducido = URL.createObjectURL(value)
      } else {
        this.getFilePathTraducido = `${process.env.VUE_APP_URL_API}/avatar/logo-default.png`
      }
      const avatar = {
        key: 'avatar',
        info: value,
      }
      this.$emit('input-avatar', avatar)
    },
    first_name(value) {
      const firstName = {
        key: 'first_name',
        info: value,
      }
      this.$emit('input-first-name', firstName)
    },
    last_name(value) {
      const lastName = {
        key: 'last_name',
        info: value,
      }
      this.$emit('input-last-name', lastName)
    },
    status_id(value) {
      const statusId = {
        key: 'status_id',
        info: value,
      }
      this.$emit('input-status-id', statusId)
    },
  },
  async mounted() {
    await this.getRoles()
    this.getStatus()
    await this.getTypeCargo()
    this.setProfileData()
  },
  methods: {
    setProfileData() {
      if (Object.keys(this.profileData).length > 0) {
        this.first_name = this.profileData.profile.first_name
        this.last_name = this.profileData.profile.last_name
        this.tipo_cargo_id = this.profileData.tipo_cargo_id
        this.emailUsers = this.profileData.email
        this.status_id = this.profileData.status_id
        this.setRol(this.profileData.role)
        this.tipo_cargo_id = this.profileData.user_tipo_cargo
        this.status_id = this.profileData.status_user
      }
    },
    getRoles() {
      return new Promise(resolve => {
        this.isLoadingRoles = true
        this.$http.get('/v1/roles/')
          .then(res => {
            this.isLoadingRoles = false
            this.rowsRoles = res.data
            resolve(this.rowsRoles)
          })
      })
    },
    setRol(slug) {
      this.roles = this.rowsRoles.filter(row => row.slug === slug)
    },
    getStatus() {
      this.isLoadingStatus = true
      this.$http.get('/v1/status/get/')
        .then(res => {
          this.isLoadingStatus = false
          this.rowsStatus = res.data
        })
    },
    getTypeCargo() {
      return new Promise(resolve => {
        this.isLoadingCargo = true
        this.$http.get('/v1/tipo-cargo/get/')
          .then(res => {
            this.rowsTipoCargo = res.data
            this.isLoadingCargo = false
            resolve(this.rowsTipoCargo)
          })
      })
    },
  },
}
</script>
