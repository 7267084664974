<template>
  <b-card>
    <b-card-header class="mb-0 pb-0 pt-0">
      <b-card-title id="title-card-user-travel">
        Información del viaje
      </b-card-title>
      <feather-icon
        v-b-tooltip.show.top="'Ayuda'"
        icon="HelpCircleIcon"
        size="30"
        class="cursor-pointer"
        @click="tourStart"
      />
    </b-card-header>
    <app-tour
      name="tour-user-travel"
      :steps="steps"
    />
    <hr>
    <b-row>
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span
                id="fecha-viaje"
                class="font-weight-bolder"
              >
                Fecha de viaje
              </span>
            </th>
            <td
              v-if="userData.viaje && userData.viaje.FechaViaje"
              class="pb-50"
            >
              {{ userData.viaje.FechaViaje.name }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span
                id="finan-study"
                class="font-weight-bolder"
              >
                Quien financia mis estudios
              </span>
            </th>
            <td
              v-if="userData.viaje && userData.viaje.TipoFinanciacion"
              class="pb-50"
            >
              {{ userData.viaje.TipoFinanciacion.name }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span
                id="grado-academico"
                class="font-weight-bolder"
              >
                Grado académico
              </span>
            </th>
            <td
              v-if="userData.viaje && userData.viaje.tipoGradoAcademico"
              class="pb-50"
            >
              {{ userData.viaje.tipoGradoAcademico.name }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span
                id="carrera-estudio"
                class="font-weight-bolder"
              >
                Carrera de estudio
              </span>
            </th>
            <td
              v-if="userData.viaje"
              class="pb-50"
            >
              {{ userData.viaje.carrera_estudio }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span
                id="ciudad-universidad"
                class="font-weight-bolder"
              >
                Ciudad de la universidad
              </span>
            </th>
            <td
              v-if="userData.viaje && userData.viaje.ciudadUniversidad"
              class="pb-50"
            >
              {{ userData.viaje.ciudadUniversidad.name }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span
                id="universidad"
                class="font-weight-bolder"
              >
                Universidad
              </span>
            </th>
            <td
              v-if="userData.viaje && userData.viaje.universidad"
              class="pb-50"
            >
              {{ userData.viaje.universidad.name }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span
                id="segunda-carrera-estudio"
                class="font-weight-bolder"
              >
                Segunda carrera de estudio
              </span>
            </th>
            <td
              v-if="userData.viaje"
              class="pb-50 text-capitalize"
            >
              {{ userData.viaje.carrera_estudio_segunda_opcion }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span
                id="segunda-ciudad-universidad"
                class="font-weight-bolder"
              >
                Segunda Ciudad de universidad
              </span>
            </th>
            <td
              v-if="userData.viaje"
              class="pb-50"
            >
              <template v-if="userData.viaje.ciudadUniversidadSegunda">
                {{ userData.viaje.ciudadUniversidadSegunda.name }}
              </template>
              <template v-else>
                SIN INFORMACIÓN
              </template>
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span
                id="segunda-universidad"
                class="font-weight-bolder"
              >
                Universidad
              </span>
            </th>
            <td
              v-if="userData.viaje"
              class="pb-50"
            >
              <template v-if="userData.viaje.universidadSegunda">
                {{ userData.viaje.universidadSegunda.name }}
              </template>
              <template v-else>
                SIN INFORMACIÓN
              </template>
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span
                id="tipo-estudio"
                class="font-weight-bolder"
              >
                Tipo de estudio
              </span>
            </th>
            <td
              v-if="userData.viaje && userData.viaje.TipoEstudio"
              class="pb-50"
            >
              {{ userData.viaje.TipoEstudio.name }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span
                id="institucion-academica"
                class="font-weight-bolder"
              >
                Institución académica
              </span>
            </th>
            <td
              v-if="userData.viaje"
              class="pb-50 text-capitalize"
            >
              {{ userData.viaje.institucion_academica }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ClockIcon"
                class="mr-75"
              />
              <span
                id="fecha-termino-estudios"
                class="font-weight-bolder"
              >
                Fecha en la que termino estudios
              </span>
            </th>
            <td
              v-if="userData.viaje"
              class="pb-50 text-capitalize"
            >
              {{ userData.viaje.fecha_terminacion }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
        </table>
      </b-col>
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-xl-0 w-100">
          <tr>
            <th>
              <feather-icon
                icon="BookmarkIcon"
                class="mr-75"
              />
              <span
                id="preparatoria"
                class="font-weight-bolder"
              >
                Preparatoria
              </span>
            </th>
            <td v-if="userData.viaje">
              {{ userData.viaje.preparatoria ? 'Si' : 'No' }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span
                id="lugar-nacimiento"
                class="font-weight-bolder"
              >
                Lugar de nacimiento
              </span>
            </th>
            <td
              v-if="userData.viaje"
              class="pb-50 text-capitalize"
            >
              {{ userData.viaje.lugar_nacimiento }} - {{ FormatDate(userData.viaje.date_of_birth) }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BookmarkIcon"
                class="mr-75"
              />
              <span
                id="documento-identidad"
                class="font-weight-bolder"
              >
                Documento de identidad
              </span>
            </th>
            <td
              v-if="userData.viaje"
              class="pb-50 text-capitalize"
            >
              {{ userData.viaje.tipoDocumento.name }} - {{ userData.viaje.tarjeta_identidad }} - {{ userData.viaje.expedicion }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BookmarkIcon"
                class="mr-75"
              />
              <span
                id="pasaporte"
                class="font-weight-bolder"
              >
                Pasaporte
              </span>
            </th>
            <td
              v-if="userData.viaje"
              class="pb-50 text-capitalize"
            >
              {{ userData.viaje.pasaporte }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ClockIcon"
                class="mr-75"
              />
              <span
                id="fecha-expedicion"
                class="font-weight-bolder"
              >
                Fecha de expedición
              </span>
            </th>
            <td
              v-if="userData.viaje"
              class="pb-50 text-capitalize"
            >
              {{ FormatDate(userData.viaje.pasp_expedicion) }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ClockIcon"
                class="mr-75"
              />
              <span
                id="fecha-vencimiento"
                class="font-weight-bolder"
              >
                Fecha de vencimiento
              </span>
            </th>
            <td
              v-if="userData.viaje"
              class="pb-50 text-capitalize"
            >
              {{ FormatDate(userData.viaje.pasp_vencimiento) }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="BookmarkIcon"
                class="mr-75"
              />
              <span
                id="visa-americana"
                class="font-weight-bolderer"
              >
                Visa americana
              </span>
            </th>
            <td v-if="userData.viaje">
              {{ userData.viaje.visa_americana ? 'Si' : 'No' }}
            </td>
            <td
              v-else
              class="pb-50"
            >
              SIN INFORMACIÓN
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { FormatDate } from '@core/utils/filter'
import {
  BCard,
  BRow,
  BCol,
  BCardTitle,
  BCardHeader,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AppTour from '@core/components/app-tour/AppTour.vue'
import stepsUsers from '@tour/tourUserTravelData.js'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardHeader,
    AppTour,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup() {
    return {
      FormatDate,
    }
  },
  data() {
    return {
      steps: [],
    }
  },
  mounted() {
    this.steps = stepsUsers
  },
  methods: {
    tourStart() {
      this.$tours['tour-user-travel'].start()
    },
  },
}
</script>
