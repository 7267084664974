var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',{staticClass:"justify-content-md-center"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Nombre","label-for":"users-name"}},[_c('validation-provider',{attrs:{"name":"Nombre","vid":"nombre","rules":"required|alphaSpaces","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"users-name","placeholder":"Nombre","state":errors.length > 0 ? false : null,"name":"users-name","disabled":!_vm.$can('update', 'profile')},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Apellidos","label-for":"users-apellido"}},[_c('validation-provider',{attrs:{"name":"Apellidos","vid":"apellidos","rules":"required|alphaSpaces","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"users-apellido","placeholder":"Apellidos","state":errors.length > 0 ? false : null,"name":"users-apellido","disabled":!_vm.$can('update', 'profile')},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"users-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"users-email","placeholder":"Email","state":errors.length > 0 ? false : null,"name":"users-email","disabled":!_vm.$can('update', 'profile')},model:{value:(_vm.emailUsers),callback:function ($$v) {_vm.emailUsers=$$v},expression:"emailUsers"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Rol","label-for":"users-roles"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Rol","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"users-roles","label":"name","loading":_vm.isLoadingRoles,"disabled":!_vm.isAdmin,"options":_vm.rowsRoles,"placeholder":"Seleccione una opción"},model:{value:(_vm.roles),callback:function ($$v) {_vm.roles=$$v},expression:"roles"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Tipo de cargo","label-for":"users-tipo_cargo_id"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Tipo de cargo","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"users-tipo_cargo_id","label":"name","disabled":!_vm.isAdmin,"loading":_vm.isLoadingCargo,"options":_vm.rowsTipoCargo,"placeholder":"Seleccione una opción"},model:{value:(_vm.tipo_cargo_id),callback:function ($$v) {_vm.tipo_cargo_id=$$v},expression:"tipo_cargo_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Estado","label-for":"users-status_id"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Estado","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"users-status_id","label":"name","disabled":!_vm.isAdmin,"loading":_vm.isLoadingStatus,"options":_vm.rowsStatus,"placeholder":"Seleccione una opción"},model:{value:(_vm.status_id),callback:function ($$v) {_vm.status_id=$$v},expression:"status_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }